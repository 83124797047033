<template>
  <div class="content">
    <div class="div-flex" style="margin: 20px">
      <div class="btnBox ms-4" @click="backFn">
        <img src="@/assets/img/back_001.png" width="16px" />
        {{ $t('back_lang') }}
      </div>
    </div>
    <div class="content-text" :class="{
      'content-text1': routeName == 'arabFund',
      'content-text2': routeName == 'beeFinancialServiceGroup',
      'content-text3': routeName == 'arabExportAndImportBank'
    }">
      <div class="title-box">
        <h2 class="text-center text-title text-title1">{{ $t('arabFundTitle1') }}</h2>
        <h2 class="text-center text-title text-title2">{{ $t('arabFundTitle2') }}</h2>
        <!-- <img src="@/assets/img/content-bg-top.png" alt=""> -->
      </div>
      <div class="content_text">
        <img :src="logSrc" style=" display: block; margin: 0 auto;" alt="">
        <div class="htmlText" v-html="htmlText"></div>
        <!-- <img src="@/assets/img/content-bottom.png" width="100%"  alt=""> -->
      </div>
    </div>
    <!-- <img :src="imgSrc" style="display: block; margin: 0 auto; padding: 10px; border: 1px solid #ccc" alt="" /> -->
  </div>
</template>

<script setup lang="ts">
import router from '@/router'
import { useRoute } from 'vue-router'
import { onMounted, ref, watch } from 'vue'
import textObj from './arabFund'
const route = useRoute()
const htmlText = ref()
// 定义一个函数，用于回到页面顶部
function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth' // 可选，平滑滚动效果
  });
}
// const obj = {
//   bankTitle:{
//     title1:'arabFundTitle1',
//     title2:'arabFundTitle2'
//   },
//   beeTitle:{
//     title1:'bankTitle1',
//     title2:'bankTitle2'
//   },
//   fundTitle:{
//     title1:'beeTitle1',
//     title2:'beeTitle2'
//   }
// }
// const titleObj = ref({
//   title1:'',
//   title2:''
// })
const lang = window.localStorage.getItem('language')
const langObj = textObj[lang || 'en']
const routeName = ref<any>('')
const logSrc = ref<any>()
onMounted(() => {
  scrollToTop()
  console.log(route.name)
  routeName.value = route.name
  if (route.name == 'arabFund') {
    htmlText.value = langObj.fundText
    logSrc.value = '/src/assets/img/fund_logo.png'
    // titleObj.value = obj.fundTitle
  }
  if (route.name == 'beeFinancialServiceGroup') {
    htmlText.value = langObj.beeText
    logSrc.value = '/src/assets/img/xiaomifeng_logo.png'
    // titleObj.value = obj.beeTitle
  }
  if (route.name == 'arabExportAndImportBank') {
    htmlText.value = langObj.bankText || textObj['en'].bankText
    logSrc.value = '/src/assets/img/bank_logo.png'
    // titleObj.value = obj.bankTitle
  }
})
function backFn() {
  router.back()
}

//emit
</script>

<style lang="scss" scoped>
.content {
  width: 70%;
  margin: 0 auto;
}



.title-box {
  position: relative;
  padding-top: 100px;
  padding-bottom: 20px;

  // background:
  //   url(@/assets/img/content_top.png) no-repeat ;
  //   background-size: 100% ;
  img {
    position: absolute;
    top: 190px;
    left: 0;
    width: 100%;
    z-index: -1;
  }
}

.text-title {
  font-size: 28px;
  font-weight: bold;
}

.text-title1 {
  color: #023c80;
}

.text-title2 {
  color: #ec9209;
}

.btnBox {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 30px;
  background: #0168dd;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.content-text1 {
  background-image:
    url(@/assets/img/content_top.png),
    url(@/assets/img/content-bg-top.png),
    url(@/assets/img/content-bg.png),
    url(@/assets/img/content-bottom.png),
  ;
  background-repeat: no-repeat;
  background-size: 100%, 100%, 100%, 100%;
  background-position: 0 0, 0 13%, 0 85%, left 100%;
}

.content-text2 {
  background-image:
    url(@/assets/img/content_top.png),
    url(@/assets/img/content-bg-top.png),
    url(@/assets/img/content-bg.png),
    url(@/assets/img/content-bg-top.png),
    url(@/assets/img/content-bg.png),
    url(@/assets/img/content-bottom.png),
  ;
  background-repeat: no-repeat;
  background-size: 100%, 100%, 100%, 100%, 100%, 100%;
  background-position: 0 0, 0 180px, 0 40%, 0 65%, 0 92%, left 100%;
}

.content-text3 {
  background-image:
    url(@/assets/img/content_top.png),
    url(@/assets/img/content-bg-top.png),
    url(@/assets/img/content-bg.png),
    url(@/assets/img/content-bottom.png),
  ;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0, 0 10%, 0 92%, left 100%;
}

.htmlText {
  // background-image: url(@/assets/img/content-bg.png),url(@/assets/img/content-bg-top.png) ;
  background-repeat: no-repeat, ;
  background-size: 100%, 100%;
  background-position: left bottom, left top;
  padding: 0 30px;
  padding-bottom: 30px;

  :deep(p) {
    line-height: 50px;
  }
}
</style>
