export default {
  activates_0:
    '1) Organizing and holding semi-annual meetings of Arab and Chinese businessmen under the name of  " Arab-Chinese Businessmen\'s Forum "  in the Arab countries and Chinese provinces that wish to host such meetings.',
  activates_1:
    '2) Organizing and holding sectoral meetings in the fields of trade, industry, agriculture, tourism, transport, contracting, banking, or any other economic sectors in order to coordinate Arab and Chinese businessmen working in these fields.',
  activates_2:
    '3) Organizing conferences and business meetings (B2B) and permanent and seasonal exhibitions in the Arab countries and provinces of China.',
  activates_3: '4) Preparing specialized economic studies.',
  activates_4:
    '5) Establishing and managing comprehensive commercial showrooms in Arab and Chinese provinces.',
  activates_5:
    '6) Acting as a link between ministries, institutions, and organizations in China and Arab countries, so as to promote economic development and reduce obstacles to the flow of people and goods involved in China-Arab economic and trade cooperation.',
  activates_6:
    '7) Acting as representatives of Arab and Chinese ministries, institutions, and organizations when dealing with similar foreign institutions.',
  activates_7:
    '8) Participating in Arab, Chinese and international meetings related to the activities of Arab and Chinese businessmen.',
  activates_8:
    '9) Opening channels of cooperation with regional economic groups to achieve different gains for the Arab-Chinese business community.',
  activates_9:
    '10) Organizing public and sectoral training courses and seminars in the Arab countries, Chinese provinces, or world capitals to show the importance of the Arab-Chinese Business Forum as a sponsor of activities of Arab and Chinese businessmen.',
  activates_10:
    '11) Issuing the Arabic-Chinese Business Directory and information bulletins periodically in Arabic, Chinese and English.',
  activates_11: '12) Establishing specialized specialist portals.',
  activates_12:
    '13) Considering all commercial disputes between the Arab and Chinese companies or between them and others, whether they are natural or legal persons.',
  activates_13: '14) Providing specialized legal consultations in commercial contracts.',
  activates_14:
    '15) Formulating and writing local, regional and international commercial contracts.',
  activates_15:
    '16) Conducting legal monitoring and follow-ups of the implementation of commercial contracts.',
  activates_16:
    '17) The platform signed a memorandum of cooperation with the Arab Import and Export Business Federation',
  arabAfrican_0: 'Forum Goals:',
  arabAfrican_1:
    'The Forum aims to create interaction and strengthen ties, cooperation and coordination between Arab businessmen and Chinese businessmen from various economic sectors to promote Arab-Chinese economic integration in order to achieve the following objectives:',
  arabAfrican_2:
    '1) Documenting and developing economic relations between Arab and Chinese businessmen within the framework of the Silk Road Initiative.',
  arabAfrican_3:
    '2) Increasing the volume of trade exchange between the Arab countries and China, and discussing the programs and mechanisms that achieve this, identifying obstacles, and developing practical solutions that help to remove them.',
  arabAfrican_4:
    '3) Cooperating with the Arab and Chinese institutions concerned with the development of the inter-Arab Arab trade, benefiting from the programs of these financial and technical institutions and emphasizing the importance of their role in promoting joint Arab-Chinese economic work.',
  arabAfrican_5:
    '4) Promoting joint Arab-Chinese projects within a long-term integration framework.',
  arabAfrican_6:
    '5) Presenting a series of special offers to the investment environment in China, industrial experiences and the successful corporate path, and benefiting from experiences and exchange of experiences among the participants.',
  arabAfrican_7:
    '6) Creating a typical business environment and opening new horizons for Arab and Chinese businessmen and businesswomen.',
  arabAfrican_8:
    '7) Developing pioneering ideas that will benefit the Arab economy by building joint projects and developing industries.',
  arabAfrican_9:
    '8) Organizing economic activities, activating business, investment opportunities, partnerships and investment.',
  general_01: 'General Secretariat',
  general_02:
    "Kingdom of Saudi Arabia - State of the United Arab Emirates - Sultanate of Oman - Kingdom of Bahrain - State of Qatar - Republic of Iraq - State of Lebanon - State of Palestine - State of Libya Kingdom of Morocco - People's Democratic Republic of Algeria - People's Republic of China - Republic of Ukraine",
  ContactUs_01: 'Presidency of the Union:',
  ContactUs_02: 'Egypt',
  ContactUs_03: 'General Secretariat (Headquarters):',
  ContactUs_04: 'Khartoum City - Republic of Sudan',
  ContactUs_05: 'Contact Name:',
  ContactUs_06: 'E-Mail:',
  ContactUs_07: 'Telephone:',
  ContactUs_08: 'Website:',
  ContactUs_09: 'China offices:',
  ContactUs_10: "Xi'an, Shaanxi Province, China",
  Establishment_01:
    'The Union is an international Arab organization that operates under the name “Arab Exporters and Importers Union”. It is one of the specialized Arab unions operating within the scope of the Council of Arab Economic Unity, in accordance with the Basic Provisions Agreement ratified by the Arab parliaments, and their Majesties and Excellencies the Kings and Heads of Arab States. The decision to join the union with the Council of Arab Economic Unity was issued during the eighty-third regular session held in Cairo on: 7/6/2006, and the decision to join was issued No.: 1310/D83 issued on: 7/6/2006',
  Establishment_02: 'Founding of the Union:',
  Establishment_03:
    'The Union was founded by Mrs. Amal Hassan Zaki, and its establishment was announced on: 25/12/2005',
  genral_01: 'MOSAD ABDELHAMID RASHED',
  genral_02:
    'Secretary General of the Arab Exporters and Importers Union Secretary General of the Arab-Africa-China Business Forum Siag, Chairman of Arab Automobile Manufacturing Company SHARKEYAT, President of Family Association Advisor to the General Union of Plastic Manufacturers Founder and Chairman of the Arab Center for the Information Economy',
  Media_01: 'Video',
  Media_02: 'Photo',
  Presideency_01: 'Who is Amal:',
  Presideency_02:
    'Qualification: Bachelor of Financial and Commercial Transactions, Cairo University 1996  Positions: Goodwill Ambassador for Peace, Health and Human Rights (Canadian Institute) Chairman of the Board of Directors of Nile Marketing and Commercial Investment Company S.A.E Social Work Chairman of the Board of Directors of the Association of Prisoners and their Families, Cairo Arab Economic Work for Specialized Unions, Chairman of Arab Exporters and Importers Union, Member of the Board of Directors of the General Union of Plastic Producers and Manufacturers in the Arab World, affiliated to the Council of Arab Economic Unity (League of Arab States) since 5/14/1998',
  Presideency_03:
    'The Union is an international Arab organization that operates under the name “Arab Exporters and Importers Union”. It is one of the specialized Arab unions operating within the scope of the Council of Arab Economic Unity, in accordance with the Basic Provisions Agreement ratified by the Arab parliaments, and their Majesties and Excellencies the Kings and Heads of Arab States. The decision to join the union with the Council of Arab Economic Unity was issued during the eighty-third regular session held in Cairo on: 7/6/2006, and the decision to join was issued No.: 1310/D83 issued on: 7/6/2006',
  services_01: '1. Developing and consolidating economic relations between Arab countries. ',
  services_02: '2. Supporting and developing the investment sectors in the Arab countries. ',
  services_03:
    '3. Supporting and developing the Arab economic sectors using modern management and production methods and technology. ',
  services_04: '4. Developing trade exchanges between Arab countries and friendly countries. ',
  services_05: '5. Creating e-commerce websites. ',
  services_06: '6. Settling advanced technological industries in the Arab countries. ',
  services_07: '7. Transferring modern technologies. ',
  services_08: '8. Establishing and managing Arab companies. ',
  services_09: '9. Establishing and managing banks and commercial finance institutions. ',
  services_10: '10. Managing Investments in new, existing and faltering projects',
  services_11: '11. Establishing and managing international arbitration centers. ',
  services_12: '12. Establishing and managing international research and studies centers ',
  services_13:
    '13. Establishing close relations with governments, Arab unions, bodies and institutions. ',
  services_14:
    "14. Establishing cooperation relations with foreign bodies related to the Union's field and activity. ",
  services_15: '15. Establishing and managing a specialized quality center. ',
  services_16: '16. Cooperating and participating with international quality centers. ',
  services_17:
    '17. Establishing and managing specialized international institutes, universities and academies. ',
  services_18: '18. Signing, managing and marketing commercial agencies. ',
  services_19: '19. Preparing Arab and international market studies. ',
  services_20:
    '20. Preparing indicators and marketing and economic data on Arab and international markets. ',
  services_21: '21. Organizing and managing exhibitions of products and production requirements. ',
  services_22: '22. Organizing and managing conferences, symposia and business forums (B2B). ',
  services_23: '23. Issuing magazines, bulletins, periodicals and specialized economic media. ',
  services_24: '24. Issuing the Arab International Exporters and Importers Directory. ',
  home_title: 'Council of Arab Economic Unity',
  home_title1: 'Arab Exporters and Importers Union',
  router_link_01: 'Arab Fund',
  router_link_02: 'Arab Export',
  router_link_03: 'Bank',
  router_link_04: 'Arab Bee',
  router_link_05: 'Service Group',
  router_link_06: 'Online',
  router_link_07: 'Exhibitions',
  footer_01: 'Introduction',
  footer_02: 'About Us',
  footer_03: 'User Agreement',
  footer_04: 'Logistics Policy',
  footer_05: 'Return Policy',
  footer_06: 'Safety and Security',
  footer_07: 'Import and Export',
  footer_08: 'Partners',
  footer_09: 'Find Orders',
  footer_10: 'Find Merchants',
  footer_11: 'Contact Us',
  footer_12: 'Telephone',
  footer_13: 'Address: Longchang Technology Building, No. 6,',
  footer_14: `Zhongguancun, Haidian District, Beijing`,
  footer_15: 'Pay Methods',
  footer_16: '© Copy Right: Arab Exporters and Importers Union',
  menu_text_01: 'Main',
  menu_text_02: 'Services',
  menu_text_03: 'Activities',
  menu_text_04: 'Media',
  menu_text_05: 'News',
  menu_text_06: 'Contact  Us',
  menu_text_07: 'Arab-African-Chinese Business Forum',
  menu_text_08: 'About Us',
  menu_left_text_01: 'Presidency',
  menu_left_text_02: 'Founder',
  menu_left_text_03: 'Regional Office',
  menu_left_text_04: 'General Secretariat',
  menu_left_text_05: 'Board of General Secretariat',
  menu_left_text_06: 'Establishment',
  menu_left_text_07: 'Organization Structure',
  menu_left_text_08: 'Board of the Union',
  aboutUs_01:
    'Arab Exporters and Importers Union - one of the specialized qualitative Arab unions operating within the scope of the Council of Arab Economic Unity, in accordance with the Basic Provisions Agreement ratified by Arab parliaments and Their Majesties and Excellencies, Kings and Heads of Arab States, the founder of the Union, Mrs. Amal Hassan Zaki Hassan, and the decision to join the Union to the Council of Arab Economic Unity was issued during the eighty-third regular session held in Cairo on: 7/6/2006, and the accession decision No.: 1310/D 83 was issued.',
  back_lang: 'Back',
  'UnionBoard.8Gme5mfj': 'Arab Exporters and Importers Union',
  'UnionBoard.HRBBTx53': 'Board Members',
  'UnionBoard.86nSA4X3': 'Chairman of the Board of Directors',
  'UnionBoard.DD6jetpk': 'Secretariat',
  'UnionBoard.CxFZtH5K': 'Department',
  'UnionBoard.GJ4d367G': 'Research',
  'UnionBoard.t6n5NAZR': 'Training Task',
  'UnionBoard.cr5eYhbx': 'Trade',
  'UnionBoard.taabRmfh': 'Investment',
  'UnionBoard.hPCbRnc8': 'Service',
  'UnionBoard.myYhdhpZ': 'Industry',
  'UnionBoard.6icpnnXY': 'Agriculture',
  'UnionBoard.dj8MQDea': 'International Relations',
  'UnionBoard.sB45NFYz': 'Project',
  'UnionBoard.3SCMjAWa': 'Arab Investment Fund',
  'UnionBoard.Zzhx7n8E': 'Electronic Store',
  'UnionBoard.Nt46ZsF8': 'Arab Export Bank',
  'UnionBoard.DzbsZaYA': 'Arab E-commerce Company',
  'UnionBoard.mXMrirBt': 'Arab Financial Services Company',
  'UnionBoard.3YTXDC6E': 'Arab Logistics Service Company',
  'UnionBoard.4maA4Rw8': 'Arab Sports Investment Company',
  'UnionBoard.QkiRK6Dk': 'Arab Academy of Information Economics',
  'UnionBoard.GP3KYx66': 'International Mediation, Arbitration, and Mediation Center',
  'UnionBoard.2X4sCxRA': 'Arab Economic Information Center',
  'UnionBoard.HdJXEwKW': 'Arab Training Center',
  'UnionBoard.jwisQzdk': 'Arab African Chinese Business Forum',
  'UnionBoard.HZH4KHPF': 'Assistant Secretary',
  'UnionBoard.aYSMFCFW': 'Planning and Follow-up Actions',
  'UnionBoard.8bWX2tsk': 'Membership Qualification',
  'UnionBoard.r8wApYWd': 'Human Development ',
  'UnionBoard.wA7ZcwpQ': 'Account',
  'UnionBoard.GnxiyPSw': 'News',
  'UnionBoard.YiSmrWAN': 'Technical Information',
  'UnionBoard.Dd6DPTQe': 'Exhibition and Conference Management Department',
  'UnionBoard.QQG6Dw6f': 'Management and Administration',
  'UnionBoard.5zbApfbT': 'Representative Office',
  'UnionBoard.czRwXAsE': 'Saudi Arabia',
  'UnionBoard.YHwPEF2J': 'Qatar',
  'UnionBoard.B4PfdYR6': 'Iraq',
  'UnionBoard.rsSSnCKZ': 'Bahrain',
  'UnionBoard.CDGkeN2N': 'Yemen',
  'UnionBoard.zmJ8CsMt': 'Croatia',
  'UnionBoard.AYiMiPe4': 'Palestine',
  'UnionBoard.6A4xh2Qa': 'The United Arab Emirates',
  'UnionBoard.kpQHnJcT': 'Morocco',
  'UnionBoard.fGBnKNnD': 'Algeria',
  'UnionBoard.85YXAe3n': 'Kuwait',
  'UnionBoard.TdWtT3M8': 'Lebanon',
  'UnionBoard.KWmHpA63': 'Oman',
  'UnionBoard.JzWxe6Ry': 'Mauritania',
  'UnionBoard.5b3i8Bek': 'Regional Office',
  'UnionBoard.tZPxXyAC': 'Egypt',
  'UnionBoard.BpzzitGH': 'Syria',
  'UnionBoard.fT3S2RMz': 'Jordan',
  'UnionBoard.EtGHPYN7': 'Sudan',
  'UnionBoard.PisFEbWD': 'Mechanism',
  'UnionBoard.amEFsjbh': 'Wise Man',
  'UnionBoard.4KpX5MDN': 'Leader',
  'UnionBoard.QsMpJ8mk': 'Scientist',
  'UnionBoard.a3CKYtfA': 'Teenager',
  'UnionBoard.d4cbEAbE': 'Human Rights',
  'UnionBoard.sH4TDWRi': 'Adviser',
  'UnionBoard.ejw7h5WQ': 'Expert',
  'UnionBoard.jhQtbDj4': 'Pioneer',
  'UnionBoard.e7jPskzH': 'Woman',
  'boardOfGeneral.Tc8smGQJ': 'Secretariat',
  'boardOfGeneral.cHMd8eyh': 'Department',
  'boardOfGeneral.aAtyCHzJ': 'Research',
  'boardOfGeneral.TZfDZdGi': 'Rehabilitation Training',
  'boardOfGeneral.tZMShTJP': 'Trade',
  'boardOfGeneral.kfYDrHt4': 'Investment',
  'boardOfGeneral.jzcdhcZ6': 'Service',
  'boardOfGeneral.45mkGwcr': 'Industry',
  'boardOfGeneral.8G2hTBbA': 'Agriculture',
  'boardOfGeneral.QBWwW66t': 'International Relations',
  'boardOfGeneral.6nXKdhHQ': 'Project',
  'boardOfGeneral.skbsMhKr': 'Arab Investment Fund',
  'boardOfGeneral.i8aX82MG': 'Electronic Store',
  'boardOfGeneral.dZt4pXaa': 'Arab Export Bank',
  'boardOfGeneral.3DHE8KdD': 'Arab E-commerce Company',
  'boardOfGeneral.7GcBemw7': 'Arab Financial Services Company',
  'boardOfGeneral.hbyXNFD5': 'Arab Logistics Service Company',
  'boardOfGeneral.hM3hHf6z': 'Arab Sports Investment Company',
  'boardOfGeneral.pGRZ2eZt': 'Arab Academy of Information Economics',
  'boardOfGeneral.CSY4GmyT': 'Assist Secretary',
  'boardOfGeneral.tWX4P7KA': 'Planning and Follow-up Actions',
  'boardOfGeneral.5b8GmMWn': 'Organic',
  'boardOfGeneral.3m6SAMPK': 'Human Development ',
  'boardOfGeneral.6AfP3xAE': 'Account',
  'boardOfGeneral.MydW8WaR': 'News',
  'boardOfGeneral.5EyBZXR5': 'Exhibition and Conference Management Department',
  'boardOfGeneral.K5y6wzMJ': 'Management and Administration',
  'boardOfGeneral.yDfrPhzG': 'Device',
  'boardOfGeneral.PDbiYb3W': 'Arab African Chinese Business Forum',
  'boardOfGeneral.nfBhxxJZ': 'International Mediation, Arbitration, and Mediation Center',
  'boardOfGeneral.GPziQXAc': 'Arab Center for Information Economy',
  'boardOfGeneral.yhSbms4S': 'Arab Training Center',
  'boardOfGeneral.5d2bH3QB': 'Knowledge of the Club',
  'boardOfGeneral.GTQR8YY6': 'Social Responsibility',
  'data.eP2ti2aw': 'Syria',
  'data.trewGJEH':
    'Before the turbulent situation in Syria in 2011, the economy gradually transitioned to a market economy, but was unsuccessful due to multiple factors. After the turbulent situation in Syria in 2011, the United States and other Western and regional countries imposed sanctions on Syria. Syria faced multiple pressures, including the interruption of oil exports, sharp decline in foreign exchange income, currency depreciation, rising prices, and high unemployment rate. The economic situation became increasingly severe. In June 2020, the United States announced the implementation of the Caesar Act, which imposed unilateral sanctions on Syria. The main economic data is as follows (due to the turbulent situation in Syria, the data is mainly estimated based on previous years and the latest economic situation): Gross Domestic Product: 16.5 billion US dollars (2021). Gross Domestic Product Growth Rate: -2.1% (2021). Per capita Gross Domestic Product: 948 US dollars (2021). Currency name: Syrian Pound. Unemployment rate: 78% (2020). Inflation rate: 89.2% (2021). Foreign exchange reserves: close to zero (2022)',
  'data.ab33HBek': 'Jordan',
  'data.CGks7JNM': `Jordan is a developing country with limited resources, limited arable land, and dependence on imports. The main pillars of the national economy are remittances, tourism, and foreign aid. After King Abdullah II came to power, he vigorously promoted economic reforms, improved the investment environment, actively sought foreign aid, and reversed the situation of long-term negative or zero economic growth. Joined the World Trade Organization around 1999. The economic growth rate exceeded 8% between 2004 and 2008. Since 2009, due to the impact of the international financial crisis and the turbulent situation in West Asia and North Africa, the economic growth rate has declined. The government has increased its efforts to regulate the economy and taken corresponding measures in finance, infrastructure, investment promotion, and seeking foreign aid, achieving certain results. After the end of the epidemic, Jordan's import and export trade and foreign exchange income gradually rebounded, with foreign exchange income increasing by 1.4% to reach 3.5 billion US dollars in 2023. The main economic data for the first quarter of 2024 are as follows: GDP growth rate: 2.0%. Per capita Gross Domestic Product: Approximately 3891 US dollars. Total foreign exchange reserves: 17.39 billion US dollars`,
  introduction: 'introduction:',
  nationalProject: 'National Project',
  More: 'More',
  noData: 'No Data',
  stateEnterprise: 'State Enterprises',
  detail_p1: `Recently, in order to fully leverage the Trade and Economic Multifunctional Platform for SCO Countries as a bridge and vehicle for promoting international trade, economic cooperation, and people-to-people exchanges, and to expand the scope of trade and economic cooperation between the SCO countries and Arab countries, the Trade and Economic Multifunctional Platform for SCO Countries International Exchange Center and the Arab Exporters and Importers Union signed a Memorandum of Cooperation.`,
  detail_p2: `According to the agreement, both parties will actively promote international cooperation among enterprises from the SCO as well as other regions and countries, including China and Arab countries, in areas such as transportation, international logistics, energy, finance, international trade, and industrial technology. This will create favorable conditions for deepening bilateral investment, technology, and industrial cooperation.`,
  detail_p3: `According to the agreement, both parties will actively promote international cooperation among enterprises from the SCO as well as other regions and countries, including China and Arab countries, in areas such as transportation, international logistics, energy, finance, international trade, and industrial technology. This will create favorable conditions for deepening bilateral investment, technology, and industrial cooperation.`,
  textList_01: 'Report of the Investment Forum',
  textList_02: 'Federation of Exporters Clearing Workshops',
  textList_03: 'Minister of Investment, Economic Forum X',
  textList_04: 'Untitled',
  textList_05: 'Untitled',
  arabAfrican_01: `A forum emanating from the Union of Arab Exporters and Importers, called the "Arab-Chinese
        Businessmen Forum," is established and is independent and enjoys an independent moral
        personality.`,
  arabAfrican_02: `The Forum aims to create interaction and strengthen ties, cooperation and coordination
        between Arab businessmen and Chinese businessmen from various economic sectors to promote
        the Arab-Chinese economic integration in order to achieve the following objectives:`,
  arabAfrican_03: `1) Documenting and developing economic relations between Arab and Chinese businessmen within
        the framework of the Silk Road Initiative.`,
  arabAfrican_04: `2) Increase the volume of trade exchange between the Arab countries and China, and discuss
        the programs and mechanisms that achieve this. Identify obstacles, and develop practical
        solutions that help to remove them.`,
  arabAfrican_05: ` 3) Cooperate with the Arab and Chinese institutions concerned with the development of the
        inter-Arab Arab trade and benefit from the programs of these financial and technical
        institutions and emphasize the importance of their role in promoting joint Arab-Chinese
        economic work.`,
  arabAfrican_06: ` 4) Promote joint Arab-Chinese projects within a long-term integration framework.`,
  arabAfrican_07: ` 5) Presenting a series of special offers to the investment environment in China, industrial
        experiences and the successful corporate path, and benefiting from experiences and exchange
        of experiences among the participants.`,
  arabAfrican_08: ` 6) Creating a typical business environment and opening new horizons for Arab and Chinese
        business men and women.`,
  arabAfrican_09: ` 7) Develop pioneering ideas that will benefit the Arab economy by building joint projects
        and developing industries.`,
  arabAfrican_10: ` 8) Organizing economic activities, activating business, investment opportunities,
        partnership and investment`,
  arabAfrican_11: `Forum Activities:`,
  arabAfrican_12: `1) Organize and hold semi-annual meetings of Arab and Chinese businessmen under the name of
        "Arab-Chinese Businessmen's Forum" in the Arab countries and Chinese provinces that wish to
        host such meetings.`,
  arabAfrican_13: ` 2) Organizing and holding sectoral meetings in the fields of trade, industry, agriculture,
        tourism, transport, contracting, banks or any other economic sectors in order to coordinate
        Arab and Chinese businessmen working in these fields.`,
  arabAfrican_14: ` 3) Organizing conferences and business meetings (B2B) and permanent exhibitions and seasonal
        in the Arab countries and provinces of China.`,
  arabAfrican_15: `4) Preparation of specialized economic studies.`,
  arabAfrican_16: ` 5) Establishment and management of commercial complexes and showrooms in Arab and Chinese
        provinces.`,
  arabAfrican_17: `6) To act as a link between ministries, institutions, organizations and Arab and Chinese
        organizations to facilitate economic relations and overcome obstacles to the flow of money,
        people and goods between the Arab countries and China.`,
  arabAfrican_18: ` 7) To act as representative of Arab and Chinese ministries, institutions, organizations and
        organizations when dealing with similar foreign institutions`,
  arabAfrican_19: ` 8) Participation in Arab, Chinese and international meetings related to the activities of
        Arab and Chinese businessmen.`,
  arabAfrican_20: `9) Open channels of cooperation with regional economic groups to achieve different gains for
        the Arab-Chinese business community`,
  arabAfrican_21: `10) Organizing public and sectoral training courses and seminars in the Arab countries, the
        Chinese provinces or in the world capitals to show the role and importance of the
        Arab-Chinese Business Forum as a sponsor of the activities of Arab and Chinese businessmen.`,
  arabAfrican_22: ` 11) Issuing the Arabic-Chinese Business Directory periodically in Arabic, Chinese and
        English, and issuing periodic and information bulletins.`,
  arabAfrican_23: ` 12) Establishment of specialized specialist portal.`,
  arabAfrican_24: `13) Consider all commercial disputes between the Arab and Chinese companies or between them
        and others, whether they are natural or legal persons.`,
  arabAfrican_25: `14) Providing specialized legal consultations in commercial contracts.`,
  arabAfrican_26: `15) Formulation and writing of local, regional and international commercial contracts.`,
  arabAfrican_27: `16) Legal monitoring and follow-up of the implementation of commercial contracts.`,
  arabFundTitle1: 'Council of Arab Economic Unity',
  arabFundTitle2: 'Arab Exporters and Importers Union',
  founder_01: `Founding of the Federation The Federation was founded by Mrs. Amal Hassan Zaki, and its
      establishment was announced on: 25/12/2005`,
  founder_02: `The Union is an international Arab organization that operates under the name 
“The Union of Arab Exporters and Importers.” It is one of the specialized Arab unions 
operating within the scope of the Council of Arab Economic Unity, in accordance with 
the Basic Provisions Agreement ratified by the Arab parliaments, and their Majesties 
and Excellencies the Kings and Heads of Arab States.
`,
  founder_03: `
The decision to join the union with the Council of Arab Economic Unity was 
issued during the eighty-third regular session held in Cairo on: 7/6/2006, and the 
decision to join was issued No.: 1310/D83 issued on: 7/6/2006.`,
founder_title_01:`Founding of the Federation:`,
founder_title_02:`Legitimacy of the union:`,
  // bankTitle1:'Council of Arab Economic Unity',
  // bankTitle2:'Arab Exporters and Importers Union',
  // beeTitle1:'Council of Arab Economic Unity',
  // beeTitle2:'Arab Exporters and Importers Union'
}
