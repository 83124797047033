<template>
  <div class="div-flex">
    <img src="@/assets/img/services_03.jpg" class="left_img" alt="" />
    <div style="padding-left: 20px; width: 100%; padding-top: 20px" class="bg_box pre_bgc_one">
      <p v-for="(item, index) in listData" :key="index">{{ item }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
let { t } = useI18n()
defineOptions({
  name: 'ServicesPage'
})
let listData = [
  t('services_01'),
  t('services_02'),
  t('services_03'),
  t('services_04'),
  t('services_05'),
  t('services_06'),
  t('services_07'),
  t('services_08'),
  t('services_09'),
  t('services_10'),
  t('services_11'),
  t('services_12'),
  t('services_13'),
  t('services_14'),
  t('services_15'),
  t('services_16'),
  t('services_17'),
  t('services_18'),
  t('services_19'),
  t('services_20'),
  t('services_21'),
  t('services_22'),
  t('services_23'),
  t('services_24')
]
//emit
</script>

<style lang="scss" scoped>
.bg_box {
  position: relative;
  overflow: hidden;
}
.bg_img {
  background-size: 100%;
  background-position: 0 -368px;
}
p {
  line-height: 30px;
  // text-align: left;
}
.left_img {
  width: calc(100% / 6 - 10px);
  height: 300px;
}
.item {
  width: calc(100% / 5 - 29px);
  border-radius: 12px;
  padding: 16px;
  padding-right: 10px;
  height: 372px;
  background: rgba(1, 104, 221, 1);
  opacity: 0.8;
}
.item:nth-child(1) {
  background-image: url(@/assets/img/services_11.png);
  background-repeat: no-repeat;
  background-size: 119px 118px;
  background-position: right 95%;
}
.item:nth-child(2) {
  background-image: url(@/assets/img/services_03-06.png);
  background-repeat: no-repeat;
  background-size: 95px 121px;
  background-position: right 95%;
}
.item:nth-child(3) {
  background-image: url(@/assets/img/services_03-08.png);
  background-repeat: no-repeat;
  background-size: 98px 121px;
  background-position: right 95%;
}

.item:nth-child(4) {
  background-image: url(@/assets/img/services_03.png);
  background-repeat: no-repeat;
  background-size: 106px 124px;
  background-position: right 95%;
}
.item:nth-child(5) {
  background-image: url(@/assets/img/services_14.png);
  background-repeat: no-repeat;
  background-size: 83px 116px;
  background-position: right 95%;
}
</style>
